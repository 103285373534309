import React from 'react';
import { graphql, PageProps } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { Button } from 'gatsby-theme-material-ui';
import { Helmet } from 'react-helmet';

import Starfield from '../components/starfield/index';

export const pageQuery = graphql`
  query LogoQuery {
    avatar: file(absolutePath: { regex: "/logo_gold.png/" }) {
      childImageSharp {
        fixed(width: 96, height: 96, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

// Pages are determined by hierarchy in the /pages folder.
// This component, saved in the index.tsx file, is mapped
// to the / page.
const Home: React.FC<PageProps> = (props) => (
  <>
    <Helmet title={'Trapped In A Universe'} />
    <Starfield style={{ position: 'absolute', width: '100%', height: '100%' }} />
    <div className="global-wrapper" data-is-root-path={true}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <GatsbyImage fixed={props.data?.avatar?.childImageSharp?.fixed} alt="trapped in a universe logo" />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 8 }}>
          <Button
            color="secondary"
            variant="text"
            to="https://github.com/nvelastegui"
            style={{ textTransform: 'lowercase' }}
          >
            github
          </Button>
          <Button
            color="secondary"
            variant="text"
            style={{ textTransform: 'lowercase' }}
            to="https://www.linkedin.com/in/nvelastegui/"
          >
            linkedin
          </Button>
        </div>
      </div>
    </div>
  </>
);

export default Home;
