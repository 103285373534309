import React from 'react';

import sizeMe from 'react-sizeme';
import raf from 'raf';

import Particle from './particle';

const Z_MAX_BOUND = 1000;
const PARTICLE_DENSITY = 0.00022;
interface Props {
  lineWidth?: number;
  alphaFactor?: number;
  depth?: number;
  style?: object;
  size: {
    width: number;
    height: number;
  };
}

interface Origin {
  x: number;
  y: number;
}

interface State {
  origin: Origin;
  width: number;
  height: number;
}

class StarfieldAnimation extends React.Component<Props, State> {
  _particles = new Array<Particle>();
  _canvas: any;
  tickRaf: any;
  resetTimeout: number | undefined;

  state = {
    origin: {
      x: this.props.size.width / 2,
      y: this.props.size.height / 2,
    },
    width: this.props.size.width,
    height: this.props.size.height,
  };

  componentWillMount() {
    this.resetParticles(this.state.width, this.state.height, this.state.origin);
  }

  componentDidMount() {
    this._tick();
  }

  componentWillUnmount() {
    raf.cancel(this.tickRaf);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.size.width !== this.props.size.width || prevProps.size.height !== this.props.size.height) {
      const newOrigin = { x: this.props.size.width / 2, y: this.props.size.height / 2 };
      if (this.resetTimeout !== undefined) {
        clearTimeout(this.resetTimeout);
      }
      this.resetTimeout = setTimeout(() => {
        this.setState({
          width: this.props.size.width,
          height: this.props.size.height,
          origin: newOrigin,
        });
        this.resetParticles(this.props.size.width, this.props.size.height, newOrigin);
      }, 50);
    }
  }

  setCanvasRef = (ref: any) => {
    this._canvas = ref;
  };

  _tick = () => {
    this.updateParticles();
    this._draw();
    this.tickRaf = raf(this._tick);
  };

  updateParticles() {
    for (let i = 0; i < this._particles.length; ++i) {
      this._particles[i].update();
    }
  }

  _draw() {
    if (!this._canvas) return;
    const ctx = this._canvas.getContext('2d');
    const alphaFactor = this.props.alphaFactor ?? 1;

    ctx.save();
    ctx.translate(this.state.origin.x, this.state.origin.y);
    ctx.clearRect(-this.state.origin.x, -this.state.origin.y, this.state.width, this.state.height);
    ctx.lineWidth = this.props.lineWidth ?? 2;

    for (let i = 0; i < this._particles.length; ++i) {
      const p = this._particles[i];
      const depth = this.props.depth ?? 300;

      p.s = depth / (depth + p.z);
      p.sx = p.x * p.s;
      p.sy = p.y * p.s;
      p.alpha = (alphaFactor * (Z_MAX_BOUND - p.z)) / (Z_MAX_BOUND / 2);

      if (p.osx !== 0 || p.osy !== 0) {
        ctx.beginPath();
        ctx.moveTo(p.sx, p.sy);
        ctx.lineTo(p.osx, p.osy);
        ctx.strokeStyle = 'hsla(' + p.hue + ', 100%, ' + p.lightness + '%, ' + p.alpha + ')';
        ctx.stroke();
      }
    }

    ctx.restore();
  }

  resetParticles(newWidth: number, newHeight: number, newOrigin: Origin) {
    const { depth = 300 } = this.props;
    const numberOfParticles = Math.floor(newWidth * newHeight * PARTICLE_DENSITY);
    if (this._canvas && this._particles.length > 0) {
      const ctx = this._canvas.getContext('2d');
      ctx.clearRect(-newOrigin.x, -newOrigin.y, newWidth, newHeight);
    }
    this._particles = [];
    const particleDefaults = {
      depth: depth,
      width: newWidth,
      height: newHeight,
      x: { min: -newOrigin.x, max: newWidth - newOrigin.x },
      y: { min: -newOrigin.y, max: newHeight - newOrigin.y },
      z: { min: -depth, max: Z_MAX_BOUND },
    };
    for (let i = 0; i < numberOfParticles; ++i) {
      this._particles.push(new Particle(particleDefaults));
    }
  }

  render() {
    return (
      <div
        style={{
          overflow: 'hidden',
          ...this.props.style,
        }}
      >
        <canvas ref={this.setCanvasRef} width={this.state.width} height={this.state.height} />
      </div>
    );
  }
}

export default sizeMe({ monitorWidth: true, monitorHeight: true })(StarfieldAnimation);
